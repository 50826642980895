@import './_vendor/tailwind';

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

body {
  font-family: "Poppins",sans-serif !important;
}

.fade-in {
  animation: fadein 0.66s ease-in-out;
}
